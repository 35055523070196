<template>
  <v-container fluid>
    <v-data-table
      class="pt-3"
      flat
      :loading="loading ? '#144881' : null"
      :headers="headers"
      :search="searchData"
      :items="Teachers"
      :items-per-page="5"
      sort-by=""
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Enseignants</v-toolbar-title>

          <v-spacer></v-spacer>
          <template>
            <v-btn
              color="#144881"
              dark
              class="mb-2 mr-8"
              @click="addItem"
              v-bind="attrs"
              outlined
              pill
              v-on="on"
              rounded
            >
              <v-icon left> mdi-account-outline </v-icon>
              Ajout
            </v-btn>
          </template>
        </v-toolbar>
        <v-container fluid>
          <v-expansion-panels flat style="border: 1px solid #144881">
            <v-expansion-panel class="elevation-0 pa-0">
              <v-expansion-panel-header>
                Tri / Recherche ...
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12" md="12">
                  <v-text-field
                    color="#144881"
                    label="Recherche par  année scolaire"
                    class="pt-5 pa-2"
                    filled
                    prepend-inner-icon="mdi-magnify"
                    dense
                    v-model="searchData"
                    outlined
                    flat
                    background-color="grey lighten-4"
                    rounded
                  ></v-text-field>
                </v-col>
              
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu elevation="0" right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
              <v-icon color="success" size="30">mdi-forwardburger</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="'/faculty/'+item._id">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    v-bind="attrs"
                    v-on="on"
                    color="green"
                    >mdi-arrow-right</v-icon
                  >
                </v-btn>
                </router-link>
              </template>
              <span>Déveloper</span>
            </v-tooltip> -->
            <v-tooltip bottom color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="updateItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                    >mdi-pencil-box-multiple-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>
             <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x 
      v-if="editorDialog"
      :fields="fields" 
      :model="Model" 
      :callback="callBack"
      @success = "successEditor" 
      :title="
          Model && Model._id
            ? `Modification d'un enseignant`
            : `Ajout d'un enseignant`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
        <delete-dialog :id="Model && Model._id ? Model._id : '' " :callback="callBack" @success = "successEditor"  />
    </v-dialog>
  
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../utils/data";
import { addTeacher, updateTeacher,deleteTeacher } from "../../api/user";
import EditorX from "../../components/universal/EditorX.vue";
import DeleteDialog from "../../components/universal/DeleteDialog.vue";
export default {
  name: "Teachers",
  components: {
    EditorX,
    DeleteDialog
},
  data: () => ({
    callBack:"",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
    {
        text: "Actions",
        width: "100px",
        align: "left",
        value: "actions",
  sortable: true,
      },
          {
        text: "Nom",
        sortable: true,
        value: "lastName",
      },
          {
        text: "Prénoms",
        sortable: true,
        value: "firstName",
      },
          {
        text: "Grade",
        sortable: true,
        value: "gradeName",
      },
      
    
     
    ],
    editedIndex: -1,
    Model: {
    },
    defaultItem: {
    },
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Teachers","Grades"]),
    fields() {
      return [
       
       
        {
          name: "lastName",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "firstName",
          label: "Prénoms",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
          {
          name: "grade",
          label: "Grade",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getGrades,
              params: {  },
              getter: this.Grades,
              fieldName: "name",
            },
          },
        },
      ];
    },
  },

  watch: {
  
  },
  methods: {
    ...mapActions(["getTeachers","getGrades"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getTeachers();
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les données")
        );
      }
      this.loading = false;
    },
     successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addTeacher
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateTeacher
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteTeacher
    },
  },
};
</script>

<style >

</style>
